import React, { Component } from "react";
import Layout from '../components/layout/Layout2';

const Contact = () => {
  return (
    <Layout>

      <div className="w-full h-screen container mx-auto text-center pt-40 text-white ">
        <div className="w-full">
          <h1 className="text-4xl">Interesse an einer Zusammenarbeit?</h1>
           <h2 className="text-2xl p-4">Schreib uns gerne eine Email oder ruf uns direkt an.</h2>
       
          <p><strong>Mobil:</strong> +49 176 47 68 44 77</p>
          <p><strong>Email: </strong><a className="text-white" href="mailto:kontakt@simpleheroes.de">kontakt@simpleheroes.de</a></p>

          <h3 className="text-1xl p-10 opacityPulse-css">Wir warten auf deinen Heldenruf.</h3>

        </div>
        
      </div>
    </Layout>
  );
};

export default Contact;